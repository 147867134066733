import React, { Component } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import $ from 'jquery';

export class Exclusao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventos: ""
    };
    this.excluir = this.excluir.bind(this);
  }
  
  excluir() {
    $.ajax({
      url: "/EsocialAPI/exclusao/excluir",
      type: 'post',
      data: { eventos: this.state.eventos },
      dataType: 'json',
      success: function (resposta) {
        this.showSuccess(resposta);
      }.bind(this),
      error: function (erro) {
        console.log(erro);
        this.showSuccess(erro);
      }.bind(this),
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      beforeSend: function (xhr, settings) { xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('auth-token')); }
    });
  }
  
  showSuccess(msg) {
    this.messages.show(msg);
  }

  render() {
    const style = {
      label: {
          fontSize: 16,
          fontWeight: 'bold',
          display: 'block',
      },
      dropDown: {
          width: '95%'
      },
      inputText: {
          width: '95%'
      },
      inputContainer: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      toolbar: {
          margin: 0,
          padding: 0,
      },
    }
    return (
      <>
        <div className="p-grid">
          <Messages ref={(el) => this.messages = el}></Messages>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <Panel header="Eventos a excluir">
              <div>
                <div style={style.inputContainer} className="pure-g">
                  <div className="pure-u-1-2">
                      <label style={style.label}>IDs dos Eventos da fila separados por ",": </label>
                      <InputText
                        id="eventos"
                        value={this.state.eventos}
                        style={style.inputText}
                        onChange={(e) => this.setState({ eventos: e.target.value })}
                      />
                  </div>
                </div>
              </div>
            </Panel>
            <Toolbar 
              style={style.toolbar}
              right={
                <React.Fragment>
                  <Button
                    label="Excluir"
                    id="salvar"
                    icon="pi pi-save"
                    iconPos="left"
                    style={{'margin': '5px'}}
                    onClick={this.excluir}
                  />
                </React.Fragment>
              } 
            />
          </div>
        </div>
      </>
    );    
  }
}
